import { BrowserRouter, Route, Switch , Redirect } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import '../src/Assets/css/Education/style.css';
import Home from './Containers/Front/Home';
import React from 'react';
import { Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { base_url, isLogin, postHeader } from './Helpers/request';
import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { persistor } from './Store/store';
import { isDesktop } from 'react-device-detect';
import Education from './Containers/Education/education';
import FancyColorDiamond from './Containers/Shopping/FancyColorDiamond';
const Mainrouter = React.lazy(() => import('./mainrouter'));
const MainRequest = React.lazy(() => import('./Components/mainRequest'));
const MegaMenu = React.lazy(() => import('./Containers/Header/MegaMenu'));
const Header = React.lazy(() => import('./Containers/Header/Header'));
const Footer = React.lazy(() => import('./Containers/Footer/footer'));
const App = () => {
  const [currencydata, setCurrencydata] = useState([]);

  useLayoutEffect(() => {
    //---------------------------- currency api
    axios.get(base_url + '/common/currency')
      .then(res => {
        if (res.data.status == 1) {
          setCurrencydata(res.data.data);
          localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : localStorage.setItem("bw-currency", res.data.data[0]?.currency_code);
          localStorage.getItem("bw-currency_img") ? localStorage.getItem("bw-currency_img") : localStorage.setItem("bw-currency_img", res.data.data[0]?.country_flag);
          localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : localStorage.setItem("bw-currencyicon", res.data.data[0]?.currency_icon);

        } else {
          console.log(res.data.message)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, []);

  
  
  return (
    <>
      <BrowserRouter>
        <Switch>
        <Route exact path="/">
        <Redirect to="/fancy-color-diamond" />
        </Route>
          <Suspense fallback={<><Skelton /></>}>
            <Route path='*' exact={true} component={Mainrouter} />
            {/* <Route path="/education" component={Education} /> */}
          </Suspense>
        </Switch>
        <Suspense fallback={<><Skelton /></>}>
        
          <MainRequest />
        </Suspense>
      </BrowserRouter>
    </>
  )
}

// skelton conponent
const Skelton = () => {
  return (<>
    <div className='sk' style={{ margin: "15px 0px" }}>
      <Skeleton variant="text" animation="wave" />
    </div>
  </>)
}
const Skeltonheader = () => {
  return (<>
    <div className='skh' style={{ margin: "0px 0px 10px 0px" }}>
      <Skeleton variant="text" animation="wave" height={75} />
    </div>
  </>)
}
export default App;