import React, { useEffect, useState } from "react";
import "../../Assets/css/ringsettings.css";
import "../../Assets/css/education.css";
import {
  Col,
  Container,
  Row,
  Form,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import { Breadcrumbs, Button, Typography } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import Slider from "rc-slider";
import "../../../node_modules/rc-slider/assets/index.css";
import "../../../node_modules/rc-tooltip/assets/bootstrap.css";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import {
  ErrorImg,
  base_url,
  currency,
  currencycode,
  isLogin,
  postHeader,
  setSteps,
  user,
} from "../../Helpers/request";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Ringbildermenu from "../../Components/ringbildermenu";
import ReplayIcon from "@mui/icons-material/Replay";
import { Skeleton } from "@mui/material";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { cartlengthvalue } from "../../Reducer/cartReducer";
import { whishlistlength } from "../../Reducer/wishlistReducer";
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import IonRangeSlider from 'react-ion-slider';
import { store } from "../../Store/store";
import { sessionId } from "../../Reducer/homeReducer";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const FancyColorDiamond = (props) => {
  const history = useHistory();
  const [view, setView] = useState(1);
  const [diamondList, setDiamondList] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [page, setPage] = useState(1);
  const [fancycolorlist, setFancyColorList] = useState([]);
  const [stone, setStone] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.stone
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.stone
      : ""
  );
  const [shape, setShape] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.shape
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.shape
      : ""
  );
  const [color, setColor] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.color
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.color
      : ""
  );
  const [fancyImg, setFancyImg] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.img
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.img
      : "https://showcase.belgiumwebnet.com/webapi/assets/images/fancy_diamond/color/round/yellow.png"
  );
  const [colordata, setColordata] = useState([]);

  const [caratrange, setCaratrange] = useState([]);
  const [carat, setCarat] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.carat
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.carat
      : []
  );
  const [pricerange, setPricerange] = useState([]);
  const [price, setPrice] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.price
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.price
      : []
  );
  const [intensity, setIntensity] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.intensity
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.intensity
      : [0, 6]
  );
  const [intensitydata, setIntensitydata] = useState([]);
  const [leavemouse, setLeavemouse] = useState(false);
  const [filter, setFilter] = useState([]);
  const [filtersuccess, setFiltersuccess] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [sortby, setSortby] = useState("featured");
  const [inputchange, setInputchange] = useState(false);
  const [clarity, setClarity] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.clarity
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.clarity
      : [0, 100]
  );
  const [claritydata, setClaritydata] = useState(
    JSON.parse(sessionStorage.getItem("bw_f_filter"))?.claritydata
      ? JSON.parse(sessionStorage.getItem("bw_f_filter"))?.claritydata
      : []
  );
  const [clearFdata, setClearFdata] = useState(false);
  const dispatch = useDispatch();

  const clearFilter = () => {
    sessionStorage.removeItem("bw_f_filter");
    setShape("");
    setCarat([filter?.filters?.carat?.min, filter?.filters?.carat?.max]);
    setPrice([filter?.filters?.price?.min, filter?.filters?.price?.max]);
    setColor("");
    setStone("");
    setSortby(filter?.sort_by[0]?.value);
    setClarity([0, 100]);
    setClaritydata([]);
    setIntensity([0, 6]);
    var newcolor =
      shape == ""
        ? filter?.filters?.shape[0]?.subfilter
        : filter?.filters?.shape?.filter((value) => value?.name == "Round")[0]
          ?.subfilter;
    setColordata(newcolor);
    var newintensity =
      color == ""
        ? newcolor[0]?.subfilter
        : newcolor?.filter((val) => val.name == "Yellow")[0]?.subfilter;
    setIntensitydata(newintensity);
    setClearFdata(!clearFdata);
  };

  useEffect(() => {
    setSteps(0);
    setFiltersuccess(false);
    const data = {
      currency_code: currencycode,
      setting_id: JSON.parse(localStorage.getItem("bw-settingdata"))
        ? JSON.parse(localStorage.getItem("bw-settingdata"))?.product_id
        : "",
    };
    axios
      .post(base_url + "/diamond/fancy_diamond_filter", data, {
        headers: postHeader,
      })
      .then(async (res) => {
        if (res.data.status == 1) {
          setFilter(res.data.data);
          setSortby(res.data.data?.sort_by[0]?.value);
          if (
            !JSON.parse(sessionStorage.getItem("bw_f_filter"))?.price.length
          ) {
            setPrice([
              res.data.data?.filters?.price?.min,
              res.data.data?.filters?.price?.max,
            ]);
          }
          if (
            !JSON.parse(sessionStorage.getItem("bw_f_filter"))?.carat.length
          ) {
            setCarat([
              res.data.data?.filters?.carat?.min,
              res.data.data?.filters?.carat?.max,
            ]);
          }

          res.data.data?.filters?.stone_type?.map((val) =>
            val.selected == 1 ? setStone(val.name) : ""
          );
          var selectedshape = "";
          var selectedcolor = "";
          !shape &&
            res.data.data?.filters?.shape?.map((val) => {
              if (val.selected == 1) {
                selectedshape = val.name;
                val.subfilter?.map((val2) => {
                  if (val2.selected == 1) {
                    selectedcolor = val2.name;
                  }
                });
              }
            });
          setShape(selectedshape);
          setColor(selectedcolor);
          var newcolor =
            selectedshape == ""
              ? res.data.data?.filters?.shape[0]?.subfilter
              : res.data.data?.filters?.shape?.filter(
                (value) => value?.name == selectedshape
              )[0]?.subfilter;
          setColordata(newcolor);
          var newintensity =
            selectedcolor == ""
              ? newcolor[0]?.subfilter
              : newcolor?.filter((val) => val.name == selectedcolor)[0]
                ?.subfilter;
          setIntensitydata(newintensity);
          setCaratrange(res.data.data?.filters?.carat?.range)
          setPricerange(res.data.data?.filters?.price?.range)
          setFiltersuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
  }, []);

  useEffect(() => {
    onchangesdata();
  }, [shape, color, stone, sortby, inputchange, clearFdata, carat, price]);

  useEffect(() => {
    if (filtersuccess) {
      fancylistdata();
    }
  }, [page, filtersuccess]);

  const onchangesdata = () => {
    setPage(1);
    var data1 = {
      shape,
      carat,
      price,
      color,
      stone,
      intensity,
      clarity,
      sortby,
      claritydata,
      img: fancyImg,
    };
    sessionStorage.setItem("bw_f_filter", JSON.stringify(data1));
    if (filtersuccess) {
      fancylistdata();
    }
  };
  const intensitydatasort = (obj, min, max) => {
    var data = Object.keys(obj)?.map((key) => [Number(key), obj[key]]);
    var result = [];
    console.log(data);
    var data1 = data
      .filter((val) => val[0] >= min && val[0] < max)
      ?.map((val) => result.push(val[1].value));
    return result;
  };
  const fancylistdata = () => {
    setLoader(true);
    var data = {
      currency_code: currencycode,
      start: (page - 1) * 16,
      length: 16,
      fancy_color: color,
      shape,
      clarity: claritydata,
      fancy_intensity: intensitydatasort(
        intensitydata,
        intensity[0],
        intensity[1]
      ),
      price_to: price[1],
      price_from: price[0],
      carat_from: carat[0],
      carat_to: carat[1],
      sort_by: sortby,
      user_id: user.user_id ? user.user_id : "",
      session_id: store.getState().persistedReducer.home.session_id,
      token: isLogin ? user.token : "",
      diamond_type: 4,
    };
    axios
      .post(base_url + "/diamond/diamond_list", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setTotalCount(res.data.data?.count);
          var count = (res.data.data?.count / 16).toFixed();
          if (count * 16 >= res.data.data?.count) {
            setProductCount(count);
          } else {
            setProductCount(Number(count) + 1);
          }
          setFancyColorList(res.data.data?.result);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleshape = (value) => {
    setShape(value);
    var newcolor = filter?.filters?.shape?.filter(
      (val) => val?.name == value
    )[0]?.subfilter;
    setColordata(newcolor);
    var newintensity =
      color == ""
        ? newcolor[0]?.subfilter
        : newcolor?.filter((val) => val.name == color)[0]?.subfilter;
    setIntensitydata(newintensity);
  };
  const handlecolor = (value, img) => {
    setFancyImg(img);
    setColor(value);
    var newintensity = colordata?.filter((val) => val.name == value)[0]
      ?.subfilter;
    setIntensitydata(newintensity);
  };
  const handleprice = (value) => {
    setPrice([value.from_value, value.to_value]);
  };
  const handlepriceinput = (i) => {
    var data = [];
    if (i == 0) {
      if (price[0] < filter?.filters?.price?.min) {
        data = [filter?.filters?.price?.min, price[1]];
      } else if (price[0] > filter?.filters?.price?.max) {
        data = [filter?.filters?.price?.max, price[1]];
      } else {
        data = [price[0], price[1]];
      }
    } else if (i == 1) {
      if (price[1] > filter?.filters?.price?.max) {
        data = [price[0], filter?.filters?.price?.max];
      } else if (price[1] < filter?.filters?.price?.min) {
        data = [price[0], filter?.filters?.price?.min];
      } else {
        data = [price[0], price[1]];
      }
    }
    console.log(data);
    if (data[0] < data[1]) {
      var data1 = [data[0], data[1]];
    } else {
      var data1 = [data[1], data[0]];
    }
    setPrice(data1);
    setInputchange(!inputchange);
  };

  const handlecarat = (value) => {
    setCarat([value.from_value, value.to_value]);
  };
  const handlecaratinput = (i) => {
    var data = [];
    if (i == 0) {
      if (carat[0] < filter?.filters?.carat?.min) {
        data = [filter?.filters?.carat?.min, carat[1]];
      } else if (carat[0] > filter?.filters?.carat?.max) {
        data = [filter?.filters?.carat?.max, carat[1]];
      } else {
        data = [carat[0], carat[1]];
      }
    } else if (i == 1) {
      if (carat[1] > filter?.filters?.carat?.max) {
        data = [carat[0], filter?.filters?.carat?.max];
      } else if (carat[1] < filter?.filters?.carat?.min) {
        data = [carat[0], filter?.filters?.carat?.min];
      } else {
        data = [carat[0], carat[1]];
      }
    }
    if (data[0] < data[1]) {
      var data1 = [Number(data[0]), Number(data[1])];
    } else {
      var data1 = [Number(data[1]), Number(data[0])];
    }
    setCarat(data1);
    setInputchange(!inputchange);
  };
  const datasort = (obj, min, max) => {
    var data = Object.keys(obj)?.map((key) => [Number(key), obj[key]]);
    var result = [];
    var data1 = data
      .filter((val) => val[0] > min && val[0] <= max)
      ?.map((val) => result.push(val[1]));
    return result;
  };
  const handleIntensity = (value) => {
    if (value[0] != value[1]) {
      setIntensity(value);
    }
  };
  const handleclarity = (value) => {
    if (value[0] != value[1]) {
      setClarity(value);
      var obj = filter?.filters?.clarity;
      var data = datasort(obj, value[0], value[1]);
      setClaritydata(data);
    }
  };
  const handlePageChange = (e, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };
  const addtowishlist = (product_id, wishindex) => {
    const data = {
      currency_code: currencycode,
      user_id: user.user_id ? user.user_id : "",
      session_id: store.getState().persistedReducer.home.session_id,
      product_id: product_id?.stock_no,
      type: "diamond",
      token: isLogin ? user.token : "",
    };
    axios
      .post(base_url + "/order/add_to_wishlist", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          const productlist = fancycolorlist?.map((obj, index) =>
            index == wishindex
              ? Object.assign(obj, { is_wishlist: res.data.data?.wishlist_id })
              : obj
          );
          setFancyColorList(productlist);
          dispatch(whishlistlength(res.data.data?.count));
        } else if (res.data.status == 2) {
          localStorage.removeItem("bw-user");
          dispatch(whishlistlength(0));
          dispatch(cartlengthvalue(0));
          dispatch(sessionId(''));
          history.push("/");
          window.location.reload(true);
        } else {
          console.log(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
      </Helmet>

      <div className="rcs_ringsetting_section mt-3">
        <Container className="rcs_shap-wizard_container">
          <Row className="w-100 m-auto rcs_diamond_filter_section rcs_diamond_filter_section_desktop pr-3 pl-3">
            <Col
              sm={12}
              md={12}
              lg={6}
              className="mt-5 mt-md-2 mt-lg-4 mb-1 pr-lg-4 pr-md-0 pl-md-0 mt-xl-1 rcs_fan_color"
            >
              <div className="rcs_fancy_color_tite">
                {!filtersuccess ? (
                  <>
                    {isTablet ? (
                      <Skeleton
                        variant="text"
                        style={{ transform: "unset" }}
                        animation="wave"
                        width={90}
                        height={50}
                      />
                    ) : (
                      <Skeleton
                        variant="text"
                        style={{ transform: "unset", marginBottom: "10px" }}
                        animation="wave"
                        width={90}
                        height={42}
                      />
                    )}{" "}
                  </>
                ) : (
                  <h2 title="Fancy Color">Color</h2>
                )}
              </div>
              <ul class="action-area--soLSw table-align_filter--GVxiB rcs_gemstone_color rcs_fancy_color_ul">
                {/* {!filtersuccess ? <>
                                    {isTablet ? <Skeleton variant="text" style={{ transform: 'unset' }} animation="wave" width={90} height={50} /> : <Skeleton variant="text" style={{ transform: 'unset' }} animation="wave" width={600} height={42} />} </>  */}
                {!filtersuccess ? (
                  <>
                    {[...Array(10)]?.map((val) =>
                      isTablet ? (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset", margin: "0 10px" }}
                          animation="wave"
                          width={80}
                          height={35}
                        />
                      ) : (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset", margin: "0 1px" }}
                          animation="wave"
                          width={90}
                          height={70}
                        />
                      )
                    )}
                  </>
                ) : (
                  colordata?.map((val) => (
                    <li
                      data-qa="TypeFilter-BlueSapphire"
                      onClick={() => handlecolor(val.name, val.icon)}
                      className={
                        val.status
                          ? color == val.name
                            ? "item--YBPgi selected--Qs8si2"
                            : "item--YBPgi"
                          : "item--YBPgi diamond-filter-dis"
                      }
                    >
                      <div className="rcs_fancy_img">
                        <img src={val.icon} alt={val.name} class="round_img" />
                      </div>
                      <span class="">{val.name}</span>
                    </li>
                  ))
                )}
              </ul>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={6}
              className="pl-md-0 pl-lg-4 mt-0 mt-md-2 mt-lg-4 mb-3 pr-md-0 mt-xl-1"
            >
              <div className="rcs_fancy_color_tite">
                {!filtersuccess ? (
                  <>
                    {isTablet ? (
                      <Skeleton
                        variant="text"
                        style={{ transform: "unset" }}
                        animation="wave"
                        width={90}
                        height={50}
                      />
                    ) : (
                      <Skeleton
                        variant="text"
                        style={{ transform: "unset", marginBottom: "10px" }}
                        animation="wave"
                        width={90}
                        height={42}
                      />
                    )}{" "}
                  </>
                ) : (
                  <h2 title="Fancy Shape">Shape</h2>
                )}
              </div>
              <ul className="action-area--soLSw table-align_filter--GVxiB  rcs_gemstone_color rcs_fancy_color_ul">
                {!filtersuccess ? (
                  <>
                    {[...Array(10)]?.map((val) =>
                      isTablet ? (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset", margin: "0 10px" }}
                          animation="wave"
                          width={80}
                          height={35}
                        />
                      ) : (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset", margin: "0 1px" }}
                          animation="wave"
                          width={90}
                          height={70}
                        />
                      )
                    )}
                  </>
                ) : (
                  filter?.filters?.shape?.map((val) => (
                    <li
                      data-qa="ShapeFilter-Round"
                      onClick={() => handleshape(val.name)}
                      className={
                        val.status
                          ? shape == val.name
                            ? "item--YBPgi selected--Qs8si2"
                            : "item--YBPgi"
                          : "item--YBPgi diamond-filter-dis"
                      }
                    >
                      <div className="rcs_fancy_img">
                        <img src={val.icon} alt={val.name} />
                      </div>
                      <span class="">{val.name}</span>
                    </li>
                  ))
                )}
              </ul>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="mb-3 mb-md-2 pl-0 pr-lg-4 pr-md-0 mt-md-2 mb-xl-1 pr-md-2"
            >
              <Row className="w-100 m-auto">
                <Col xs={12} sm={12} className="pl-0">
                  <div className="rcs_intensity_tite ">
                    {!filtersuccess ? (
                      <>
                        {isTablet ? (
                          <Skeleton
                            variant="text"
                            style={{ transform: "unset" }}
                            animation="wave"
                            width={90}
                            height={50}
                          />
                        ) : (
                          <Skeleton
                            variant="text"
                            style={{
                              transform: "unset",
                              marginBottom: "10px",
                            }}
                            animation="wave"
                            width={90}
                            height={42}
                          />
                        )}{" "}
                      </>
                    ) : (
                      <h2 title="Fancy Intensity">Intensity</h2>
                    )}
                  </div>
                  <div className="rcs_intensity_slider">
                    {!filtersuccess ? (
                      <>
                        {isTablet ? (
                          <Skeleton
                            variant="text"
                            style={{ transform: "unset" }}
                            animation="wave"
                            width={90}
                            height={50}
                          />
                        ) : (
                          <Skeleton
                            variant="text"
                            style={{
                              transform: "unset",
                              marginBottom: "10px",
                            }}
                            animation="wave"
                            width={600}
                            height={25}
                          />
                        )}{" "}
                      </>
                    ) : (
                      <Range
                        onAfterChange={() => onchangesdata()}
                        allowCross={false}
                        step={1}
                        min={0}
                        max={6}
                        onChange={handleIntensity}
                        value={intensity}
                        className={
                          color?.length ? color?.toLowerCase() : "yellow"
                        }
                      ></Range>
                    )}
                    <div className="rcs_intensity_slider_img">
                      <ul>
                        {!filtersuccess ? (
                          <>
                            {[...Array(6)]?.map((val) =>
                              isTablet ? (
                                <Skeleton
                                  variant="text"
                                  style={{
                                    transform: "unset",
                                    margin: "0 10px",
                                  }}
                                  animation="wave"
                                  width={80}
                                  height={35}
                                />
                              ) : (
                                <Skeleton
                                  variant="text"
                                  style={{
                                    transform: "unset",
                                    margin: "0 1px",
                                  }}
                                  animation="wave"
                                  width={90}
                                  height={70}
                                />
                              )
                            )}
                          </>
                        ) : (
                          intensitydata?.map((val, index) => (
                            <li
                              className={
                                index < intensity[0] || index + 1 > intensity[1]
                                  ? "rcs_intensity_opc_low"
                                  : "rcs_intensity_opc_high"
                              }
                            >
                              <div className="rcs_intensity_slider_img_d">
                                <img src={val.icon} alt={val.name} />
                              </div>
                              <div className="rcs_intensity_slider_text">
                                <p>{val.name}</p>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="pr-md-0 mb-lg-4 mb-md-3 p-0 pl-lg-4 pl-md-0 mt-md-2 mb-xl-1 pl-md-2"
            >
              <Row className="w-100 m-auto">
                <Col xs={12} sm={12} className="pl-0">
                  <div className="rcs_fancy_carat_tite ">
                    {!filtersuccess ? (
                      <>
                        {isTablet ? (
                          <Skeleton
                            variant="text"
                            style={{ transform: "unset" }}
                            animation="wave"
                            width={90}
                            height={50}
                          />
                        ) : (
                          <Skeleton
                            variant="text"
                            style={{
                              transform: "unset",
                              marginBottom: "10px",
                            }}
                            animation="wave"
                            width={90}
                            height={42}
                          />
                        )}{" "}
                      </>
                    ) : (
                      <h2 title="Fancy Carat">Carat</h2>
                    )}
                  </div>
                  {!filtersuccess ? (
                    <>
                      {isTablet ? (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset" }}
                          animation="wave"
                          width={90}
                          height={50}
                        />
                      ) : (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset" }}
                          animation="wave"
                          width={600}
                          height={42}
                        />
                      )}{" "}
                    </>
                  ) : (
                    <>
                      <div className="rcs_carat_slider">
                        <IonRangeSlider type="double"
                          min={filter?.normal_filters?.carat?.min}
                          max={filter?.normal_filters?.carat?.max}
                          values={caratrange}
                          // onChange={handlecarat}
                          onFinish={(e) => handlecarat(e)}
                          from={caratrange.indexOf(carat[0])}
                          to={caratrange.indexOf(carat[1])}
                        />
                      </div>
                      <Row className="mt-2">
                        <ul className="rcs_price_range_input rcs_prince_input_diamond rcs_prince_input_diamond1">
                          <li>
                            <InputGroup>
                              <FormControl
                                aria-label="Amount (to the nearest dollar)"
                                type="number"
                                step={0.01}
                                min={0}
                                name="min"
                                value={carat[0]}
                                onChange={(e) => {
                                  if (caratrange.includes(e.target.value)) {
                                    setCarat([
                                      Number(e.target.value),
                                      Number(carat[1]),
                                    ])
                                  } else {
                                    var ind = caratrange.find(val => { return val > e.target.value })
                                    caratrange.splice(caratrange.indexOf(ind), 0, e.target.value);
                                    setCarat([
                                      Number(e.target.value),
                                      Number(carat[1]),
                                    ])
                                  }
                                }
                                }
                                onBlurCapture={() => handlecaratinput(0)}
                              />
                            </InputGroup>
                          </li>
                          <li>
                            <InputGroup>
                              <FormControl
                                aria-label="Amount (to the nearest dollar)"
                                type="number"
                                step={0.01}
                                min={0}
                                name="max"
                                value={carat[1]}
                                onChange={(e) => {
                                  if (caratrange.includes(e.target.value)) {
                                    setCarat([
                                      Number(carat[0]),
                                      Number(e.target.value),
                                    ])
                                  } else {
                                    var ind = caratrange.find(val => { return val > e.target.value })
                                    caratrange.splice(caratrange.indexOf(ind), 0, e.target.value);
                                    setCarat([
                                      Number(carat[0]),
                                      Number(e.target.value),
                                    ])
                                  }
                                }
                                }
                                onBlurCapture={() => handlecaratinput(1)}
                              />
                            </InputGroup>
                          </li>
                        </ul>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="mb-3 pl-0 pl-0 mt-lg-3 mt-md-2 mb-lg-3 pr-lg-4 pr-md-0 pr-md-2"
            >
              <Row className="w-100 m-auto">
                <Col xs={12} sm={12} className="pl-0">
                  <div className="rcs_fancy_carat_tite ">
                    {!filtersuccess ? (
                      <>
                        {isTablet ? (
                          <Skeleton
                            variant="text"
                            style={{ transform: "unset" }}
                            animation="wave"
                            width={90}
                            height={50}
                          />
                        ) : (
                          <Skeleton
                            variant="text"
                            style={{
                              transform: "unset",
                              marginBottom: "10px",
                            }}
                            animation="wave"
                            width={90}
                            height={42}
                          />
                        )}{" "}
                      </>
                    ) : (
                      <h2 title="Fancy Clarity">Clarity </h2>
                    )}
                  </div>
                  {!filtersuccess ? (
                    <>
                      {isTablet ? (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset" }}
                          animation="wave"
                          width={90}
                          height={50}
                        />
                      ) : (
                        <Skeleton
                          variant="text"
                          style={{ transform: "unset" }}
                          animation="wave"
                          width={600}
                          height={42}
                        />
                      )}{" "}
                    </>
                  ) : (
                    <>
                      <div className="rcs_clarity_slider">
                        <Slider.Range
                          onAfterChange={() => onchangesdata()}
                          allowCross={false}
                          min={0}
                          marks={filter?.filters?.clarity}
                          step={null}
                          onChange={handleclarity}
                          value={clarity}
                        />{" "}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="pr-md-0 pl-lg-4 pl-md-0 mb-3 mt-lg-3 mt-md-2 mt-md-2 pl-md-2"
            >
              <Row className="w-100 m-auto">
                
                
              </Row>
            </Col>
          </Row>
          <Row className="w-100 m-auto pt-4 rcs_diamond_filter_section d-block d-md-none">
            <Col
              xs={12}
              className="p-0 rcs_filter_accordion_sec rcs_filter_accordion_sec_diamond"
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}
              >
                <AccordionSummary
                  style={{ width: "100%" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>Color and Intensity</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 pb-0">
                  <Typography className="mb-2 mb-xs-0 mt-1">
                    <Col sm={12} md={6} className="p-0">
                      <Row className="w-100 m-auto">
                        <Col xs={12} sm={12} className="p-0">
                          <ul class="action-area--soLSw table-align_filter--GVxiB rcs_gemstone_shape1 rcs_fancy_color_mobile">
                            {colordata?.map((val) => (
                              <li
                                data-qa="TypeFilter-BlueSapphire"
                                onClick={() => handlecolor(val.name, val.icon)}
                                className={
                                  val.status
                                    ? color == val.name
                                      ? "item--YBPgi selected--Qs8si2"
                                      : "item--YBPgi"
                                    : "item--YBPgi diamond-filter-dis"
                                }
                              >
                                <div className="rcs_fancy_img">
                                  <img
                                    src={val.icon}
                                    alt={val.name}
                                    class="round_img"
                                  />
                                </div>
                                <span class="">{val.name}</span>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} sm={12} className="pl-0 mt-3 mb-4">
                      <div className="rcs_intensity_tite ">
                        <h2 title="Fancy Clarity">Intensity</h2>
                      </div>
                      <div className="rcs_intensity_slider">
                        <Range
                          onAfterChange={() => onchangesdata()}
                          allowCross={false}
                          step={1}
                          min={0}
                          max={6}
                          onChange={handleIntensity}
                          value={intensity}
                          className={
                            color?.length ? color?.toLowerCase() : "yellow"
                          }
                        ></Range>
                        <div className="rcs_intensity_slider_img">
                          <ul>
                            {intensitydata?.map((val, index) => (
                              <li
                                className={
                                  index < intensity[0] ||
                                    index + 1 > intensity[1]
                                    ? "rcs_intensity_opc_low"
                                    : "rcs_intensity_opc_high"
                                }
                              >
                                <div className="rcs_intensity_slider_img_d">
                                  <img src={val.icon} alt={val.name} />
                                </div>
                                <div className="rcs_intensity_slider_text">
                                  <p>{val.name}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Col>
            <Col
              xs={12}
              className="p-0 rcs_filter_accordion_sec rcs_filter_accordion_sec_diamond"
            >
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChangeAccordion("panel2")}
              >
                <AccordionSummary
                  style={{ width: "100%" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>Can be set with</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 pb-0">
                  <Typography className="mb-2 mb-xs-0 mt-1">
                    <Col sm={12} md={6} className="p-0">
                      <Row className="w-100 m-auto">
                        <Col xs={12} sm={12} className="p-0">
                          {/* <ul className="action-area--soLSw table-align_filter--GVxiB rcs_gemstone_shape1 rcs_fancy_color_shape_mobile">
                                                        {!filtersuccess ? <>
                                                            {isTablet ? <Skeleton variant="text" style={{ transform: 'unset' }} animation="wave" width={90} height={50} /> : <Skeleton variant="text" style={{ transform: 'unset' }} animation="wave" width={600} height={42} />} </> :
                                                            filter?.filters?.shape?.map(val =>
                                                                <li data-qa="ShapeFilter-Round" onClick={() => handleshape(val.name)} className={val.status ? shape == val.name ? "item--YBPgi selected--Qs8si2" : "item--YBPgi" : "item--YBPgi diamond-filter-dis"}>
                                                                    <div className="rcs_fancy_img">

                                                                        <img src={val.icon} alt={val.name} />
                                                                    </div>
                                                                    <span class="">{val.name}</span>
                                                                </li>
                                                            )}
                                                    </ul> */}
                          <ul className="action-area--soLSw table-align_filter--GVxiB rcs_gemstone_shape1 rcs_fancy_color_shape_mobile">
                            {filter?.filters?.shape?.map((val) => (
                              <li
                                data-qa="ShapeFilter-Round"
                                onClick={() => handleshape(val.name)}
                                className={
                                  val.status
                                    ? shape == val.name
                                      ? "item--YBPgi selected--Qs8si2"
                                      : "item--YBPgi"
                                    : "item--YBPgi diamond-filter-dis"
                                }
                              >
                                <div className="rcs_fancy_img">
                                  <img src={val.icon} alt={val.name} />
                                </div>
                                <span class="">{val.name}</span>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Col>
            <Col
              xs={12}
              className="p-0 rcs_filter_accordion_sec rcs_filter_accordion_sec_diamond"
            >
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChangeAccordion("panel3")}
              >
                <AccordionSummary
                  style={{ width: "100%" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    <strong>Advance Filter</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 pb-0">
                  <Typography className="mb-2 mt-4">
                    <Col xs={12} sm={12} className="pl-0 mt-4">
                      <div className="rcs_fancy_carat_tite ">
                        {!filtersuccess ? (
                          <>
                            {isTablet ? (
                              <Skeleton
                                variant="text"
                                style={{ transform: "unset" }}
                                animation="wave"
                                width={90}
                                height={50}
                              />
                            ) : (
                              <Skeleton
                                variant="text"
                                style={{ transform: "unset" }}
                                animation="wave"
                                width={90}
                                height={42}
                              />
                            )}{" "}
                          </>
                        ) : (
                          <h2 title="Fancy Carat">Carat</h2>
                        )}
                      </div>
                      {!filtersuccess ? (
                        <>
                          {isTablet ? (
                            <Skeleton
                              variant="text"
                              style={{ transform: "unset" }}
                              animation="wave"
                              width={90}
                              height={50}
                            />
                          ) : (
                            <Skeleton
                              variant="text"
                              style={{ transform: "unset" }}
                              animation="wave"
                              width={600}
                              height={42}
                            />
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {/* <div className='rcs_carat_slider'>
                                                        <Range onAfterChange={() => onchangesdata()} step={0.01} allowCross={false} min={filter?.normal_filters?.carat?.min} max={filter?.normal_filters?.carat?.max} value={carat} onChange={handlecarat} tipFormatter={value => `${value}`} />
                                                    </div>
                                                    <ul className='rcs_price_range_input rcs_prince_input_diamond'>
                                                        <li>
                                                            <InputGroup>
                                                                <FormControl aria-label="Amount (to the nearest dollar)"
                                                                    type="int"
                                                                    name="min"
                                                                    value={carat[0]}
                                                                    onChange={(e) => setCarat([Number(e.target.value), Number(carat[1])])}
                                                                    onBlurCapture={() => handlecaratinput(0)}
                                                                />
                                                            </InputGroup>
                                                        </li>
                                                        <li>
                                                            <InputGroup>
                                                                <FormControl aria-label="Amount (to the nearest dollar)"
                                                                    type="int"
                                                                    name='max'
                                                                    value={carat[1]}
                                                                    onChange={(e) => setCarat([Number(carat[0]), Number(e.target.value)])}
                                                                    onBlurCapture={() => handlecaratinput(1)}
                                                                />
                                                            </InputGroup>
                                                        </li>
                                                    </ul> */}
                          <div className="rcs_carat_slider">
                            <IonRangeSlider type="double"
                              min={filter?.normal_filters?.carat?.min}
                              max={filter?.normal_filters?.carat?.max}
                              values={caratrange}
                              // onChange={handlecarat}
                              onFinish={(e) => handlecarat(e)}
                              from={caratrange.indexOf(carat[0])}
                              to={caratrange.indexOf(carat[1])}
                            />
                          </div>
                          <ul className="rcs_price_range_input rcs_prince_input_diamond rcs_prince_input_diamond1">
                            <li>
                              <InputGroup>
                                <FormControl
                                  aria-label="Amount (to the nearest dollar)"
                                  type="number"
                                  step={0.01}
                                  min={0}
                                  name="min"
                                  value={carat[0]}
                                  onChange={(e) => {
                                    if (caratrange.includes(e.target.value)) {
                                      setCarat([
                                        Number(e.target.value),
                                        Number(carat[1]),
                                      ])
                                    } else {
                                      var ind = caratrange.find(val => { return val > e.target.value })
                                      caratrange.splice(caratrange.indexOf(ind), 0, e.target.value);
                                      setCarat([
                                        Number(e.target.value),
                                        Number(carat[1]),
                                      ])
                                    }
                                  }
                                  }
                                  onBlurCapture={() => handlecaratinput(0)}
                                />
                              </InputGroup>
                            </li>
                            <li>
                              <InputGroup>
                                <FormControl
                                  aria-label="Amount (to the nearest dollar)"
                                  type="number"
                                  step={0.01}
                                  min={0}
                                  name="max"
                                  value={carat[1]}
                                  onChange={(e) => {
                                    if (caratrange.includes(e.target.value)) {
                                      setCarat([
                                        Number(carat[0]),
                                        Number(e.target.value),
                                      ])
                                    } else {
                                      var ind = caratrange.find(val => { return val > e.target.value })
                                      caratrange.splice(caratrange.indexOf(ind), 0, e.target.value);
                                      setCarat([
                                        Number(carat[0]),
                                        Number(e.target.value),
                                      ])
                                    }
                                  }
                                  }
                                  onBlurCapture={() => handlecaratinput(1)}
                                />
                              </InputGroup>
                            </li>
                          </ul>
                        </>
                      )}
                    </Col>
                    <Col xs={12} sm={12} className="pl-0 mt-4">
                      <div className="rcs_fancy_carat_tite ">
                        <h2 title="Fancy Clarity">Clarity </h2>
                      </div>
                      <div className="rcs_clarity_slider">
                        <Slider.Range
                          onAfterChange={() => onchangesdata()}
                          allowCross={false}
                          min={0}
                          marks={filter?.filters?.clarity}
                          step={null}
                          onChange={handleclarity}
                          value={clarity}
                        />
                      </div>
                    </Col>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col xs={12} className="p-0 mb-3 mt-4">
              <Button
                variant="outlined"
                onClick={clearFilter}
                className="rcs_clear_filter"
              >
                <ReplayIcon /> Clear Filter
              </Button>
            </Col>
          </Row>
          <Row className="w-100 d-block m-auto">
            <Col xs={12} className="p-0">
              <div className="rcs_diamond_content">
                <h1>Find Diamonds Easily</h1>
                <p>
                  The Diamond Search Feature helps you deploy various filters to
                  help you find exactly what you're looking for.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="w-100 m-auto">
            <Col xs={12} className={view == 1 ? "" : "mb-5"}>
              <div className="rcs_product_list_wrapper mt-4 p-0 w-100">
                <div className="rcs_view p-0">
                  <Row className="w-100 m-auto">
                    <Col xs={6} className="p-0">
                      <div className="rcs_mob_filter1 rcs_ring_setting_filter">
                        {loader ? (
                          <>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              className="m-auto"
                              width={150}
                              height={20}
                            />
                          </>
                        ) : (
                          <span> Showing {totalCount} Results </span>
                        )}
                      </div>
                    </Col>
                    <Col xs={6} className="p-0">
                      <div className="rcs_mob_filter1 rcs_ring_setting_filter1">
                        <span> View</span>
                        <AppsIcon
                          className={view == 1 ? "active_grid ml-2" : "ml-2"}
                          onClick={() => setView(1)}
                        />
                        <span style={{ borderRight: "1px solid #ccc" }}></span>
                        <ListIcon
                          className={view == 2 ? "active_grid ml-2" : "ml-2"}
                          onClick={() => setView(2)}
                        />
                        {/* <ul>                                                
                                                <li><AppsIcon className={view == 1 ? "active_grid" : ""} onClick={() => setView(1)} /></li>
                                                <li><span></span></li>
                                                <li><ListIcon className={view == 2 ? "active_grid" : ""} onClick={() => setView(2)} /></li>
                                            </ul> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="w-100 m-auto">
           
          </Row>

          {view == 2 ? (
            <Row className="w-100 d-block m-auto">
              <Col xs={12} className="rcs_diamond_table p-0">
                <Table
                  hover
                  responsive
                  size="sm"
                  className="rcs_diamonds_table"
                >
                  <thead>
                    <tr>
                      <th>Shape</th>
                      <th></th>
                      <th>Carat</th>
                      <th>Color</th>
                      <th>Clarity</th>
                      <th></th>
                      <th>Wishlist</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        {[...Array(15)]?.map((val) => (
                          <tr>
                            <td component="th" scope="row">
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                            <td>
                              <Skeleton
                                variant="text"
                                style={{
                                  transform: "unset",
                                  margin: "0 auto",
                                }}
                                animation="wave"
                                width={110}
                                height={30}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      fancycolorlist?.map((row, index) => (
                        <tr>
                          <td>{row.shape}</td>
                          <td>{row.fancy_color_intensity}</td>
                          <td>{row.weight}</td>
                          <td>{row.color}</td>
                          <td>{row.clarity}</td>
                         
                          <td
                            title="Add to Wishlist"
                            style={{ cursor: "pointer" }}
                          >
                            
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                history.push(
                                  "/fancy-color-diamond-detail/" + row.stock_no
                                )
                              }
                              variant="contained"
                            >
                              Details
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                <Stack spacing={2} className="mt-3 mb-3 rcs_diamond_pagination">
                  <Pagination
                    count={productCount}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"
                  />
                </Stack>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                {loader ? (
                  <>
                    {[...Array(15)].map((val) => (
                      <>
                        <Col lg={3} md={4} xs={12}>
                          <Skeleton
                            variant="text"
                            style={{
                              transform: "unset",
                              margin: "0 auto",
                              marginBottom: "15px",
                            }}
                            animation="wave"
                            height={300}
                          />
                        </Col>
                      </>
                    ))}
                  </>
                ) : (
                  fancycolorlist.map((row, index) => (
                    <Col lg={3} md={4} xs={12}>
                      <div className="rcs_diamonds_grid_content">
                        {/* <span className="rcs_diamonds_grid_info" >
                                        <img src="/static/media/inquir.9a98e4da.svg" /></span> */}
                        <span
                          className="rcs_diamonds_grid_wishlist"
                          style={{ cursor: "pointer" }}
                        >
                         
                        </span>

                        <NavLink to={"/fancy-color-diamond-detail/" + row.stock_no}>
                          <img src={row.imagelink} id={row.stock_no} onError={()=> ErrorImg("Fancy",row.stock_no)} alt="fancy-color-diamond" className />
                        </NavLink>

                        <div className="rcs_product_info">
                          <NavLink to={"/fancy-color-diamond-detail/" + row.stock_no}>
                            {" "}
                            <h3>{row.name}</h3>
                          </NavLink>
                          
                          <div className="rcs_product_tooltip">
                            <span>
                              <MoreVertOutlinedIcon />
                            </span>
                            <div className="rcs_product_tooltip_content">
                              <div className="rcs_bg-slide">
                                <li className="rcs_item-lists">
                                  <div className="rcs_left-align">Item:</div>
                                  <div className="rcs_right-align">
                                    {row.stock_no}
                                  </div>
                                </li>
                                <li className="rcs_item-lists">
                                  <div className="rcs_left-align">Shape:</div>
                                  <div className="rcs_right-align">
                                    {row.shape}
                                  </div>
                                </li>
                                <li className="rcs_item-lists">
                                  <div className="rcs_left-align">Carat:</div>
                                  <div className="rcs_right-align">
                                    {row.weight}
                                  </div>
                                </li>
                                <li className="rcs_item-lists">
                                  <div className="rcs_left-align">Color:</div>
                                  <div className="rcs_right-align">
                                    {row.color}
                                  </div>
                                </li>
                                <li className="rcs_item-lists">
                                  <div className="rcs_left-align">Clarity:</div>
                                  <div className="rcs_right-align">
                                    {row.clarity}
                                  </div>
                                </li>
                                {/* <li className="rcs_item-lists">
                                                                <div className="rcs_left-align">Cut:</div>
                                                                <div className="rcs_right-align">{row.cut_grade} </div>
                                                            </li> */}
                                {row.measurements == "" ? (
                                  ""
                                ) : (
                                  <li className="rcs_item-lists">
                                    <div className="rcs_left-align">
                                      Measure
                                    </div>
                                    <div className="rcs_right-align">
                                      {row.measurements}
                                    </div>
                                  </li>
                                )}
                                {row.lab == "" ? (
                                  ""
                                ) : (
                                  <li className="rcs_item-lists">
                                    <div className="rcs_left-align">lab</div>
                                    <div className="rcs_right-align">
                                      {row.lab}
                                    </div>
                                  </li>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
              <Row className="mt-5 mb-3">
                <Col xs={6} className="pr-0 m-auto">
                  <div className="rcs_sorting_pagi">
                    <Stack
                      spacing={2}
                      className="mt-3 mb-3 rcs_diamond_pagination diamond_p"
                    >
                      <Pagination
                        count={productCount}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                      />
                    </Stack>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/* <Row className='w-100 d-block m-auto'>
                        <Col xs={12} className='rcs_diamond_table p-0'>
                            <Table hover responsive size="sm" className='rcs_diamonds_table'>
                                <thead>
                                    <tr>
                                        <th>Shape</th>
                                        <th>Intensity</th>
                                        <th>Carat</th>
                                        <th>Color</th>
                                        <th>Clarity</th>
                                        <th>Price</th>
                                        <th>Wishlist</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ? <>
                                        {[...Array(15)]?.map(val =>
                                            <tr>
                                                <td component="th" scope="row"><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                                <td><Skeleton variant="text" style={{ transform: 'unset', margin: '0 auto' }} animation="wave" width={110} height={30} /></td>
                                            </tr>
                                        )}
                                    </>
                                    :
                                    fancycolorlist?.map((row, index) => (
                                    <tr>
                                        <td>{row.shape}</td>
                                        <td>{row.fancy_color_intensity}</td>
                                        <td>{row.weight}</td>
                                        <td>{row.color}</td>
                                        <td>{row.clarity}</td>
                                        <td style={{ fontSize: '15px', fontWeight: '600' }}>{currency}{row.sale_price}</td>
                                        <td title="Add to Wishlist" style={{ cursor: 'pointer' }}>{row.is_wishlist == "0" ? <FavoriteBorderIcon onClick={() => addtowishlist(row, index)} /> : <FavoriteIcon onClick={() => addtowishlist(row, index)} className="animate__animated animate__heartBeat" />}</td>
                                        <td><Button onClick={() => history.push("/fancy-color-diamond-detail/" + row.stock_no)} variant="contained">Details</Button></td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Stack spacing={2} className='mt-3 mb-3 rcs_diamond_pagination'>
                                <Pagination count={productCount} page={page} onChange={handlePageChange} shape="rounded" />
                            </Stack>
                        </Col>
                    </Row> */}
        </Container>
      </div>
    </>
  );
};

export default FancyColorDiamond;
